// src/config/config.js

// Determine the environment based on the frontend URL
const isSandbox = window.location.hostname.includes('sandbox.rajoffset.com') || window.location.hostname.includes('localhost');

const config = {
    API: {
        BASE_URL: isSandbox ? 'https://sandbox-account.rajoffset.com' : 'https://account.rajoffset.com',
        ENGINE_URL: isSandbox ? 'https://sandbox-engine.rajoffset.com' : 'https://engine.rajoffset.com',
    },
    FRONTEND: {
        BASE_URL: isSandbox ? 'https://sandbox.rajoffset.com' : 'https://rajoffset.com',
    },
    SUBSCRIPTION_PAYMENT_URL: isSandbox ? 'https://sandbox.rajoffset.com' : 'https://rajoffset.com',
    NETAJI_SUBSCRIPTION_PAYMENT_REDIRECT_URL: isSandbox ? 'https://sandbox.netajiposter.com' : 'https://netajiposter.com',
};

export default config; 