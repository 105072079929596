import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from '../components/Layout';
import HomePage from '../components/pages/HomePage';
import InvitationCard from '../view/cards/InvitationCard';
import Account from '../components/accounts/Account'
import Dashboard from '../view/dashboard/Dashboard';
import Login from '../login/Login';
import MyCards from '../view/dashboard/MyCards';
import Invoices from '../view/dashboard/Invoices';
import FaimlyAndFriends from '../view/dashboard/FaimlyAndFriends';
import Feedback from '../view/dashboard/Feedback';
import Form from '../components/Form'
import DragAndDrop from '../components/DragAndDrop';
import FAQ from '../components/component/FAQ';
import CardLayout from '../view/cards/CardLayout';
import InvitationCardMaster from '../view/cards/InvitationCardMaster';

import ComingSoon from '../view/coming_soon/ComingSoon';
import PrivacyPolicy from '../view/common/PrivacyPolicy';
import RefundPolicy from '../view/common/RefundPolicy';
import TermsAndConditions from '../view/common/TermsAndConditions';
import DeleteAccountAndPolicy from '../view/common/DeleteAccountAndPolicy';
import SupportTicket from '../view/common/SupportTicket';
import DeleteAccountPage from '../view/common/DeleteAccountPage'
import ContentTakedownRequestPage from '../view/common/ContentTakedownRequestPage';
import ProtectedRoutes from './protectedRoutes';
import CompleteProfile from '../view/dashboard/CompleteProfile';
import PaymentSuccesssScreen from '../view/payment_screen/PaymentSuccesssScreen';
import PaymentWaitingScreen from '../view/payment_screen/PaymentWaitingScreen';
import PaymentFailed from '../view/payment_screen/PaymentFailed';
import AndroidAppPage from '../view/coming_soon/AndroidAppPage';
import IosAppPage from '../view/coming_soon/IosAppPage';
import CardSubmittedSuccessfullyScreen from '../view/cards/CardSubmittedSuccessfully';
import AboutUsPage from '../view/support_pages/AboutUsPage';
import ContactUsPage from '../view/support_pages/ContactUsPage';
import MyProfile from '../view/profile/MyProfile';
import CreateProfile from '../view/profile/CreateProfile';
import EditProfileDetails from '../view/profile/EditProfileDetails';
import DiwaliOffer from '../components/pages/DiwaliOffer';
import CarouselComponent from '../view/cards/CarouselComponent';

import PublicPageForOrders from '../view/orders/PublicPageForOrders';

import FontStyle from '../view/common/FontStyle';
import BundlesPage from '../view/e_invite_bundles/BundlesPage';
import BundleCodeDetails from '../view/e_invite_bundles/BundleCodeDetails';
import Wedding11Details from '../view/e_invite_bundles/Wedding11Details';
import Wedding49Details from '../view/e_invite_bundles/Wedding49Details';
import Wedding199Details from '../view/e_invite_bundles/Wedding199Details';
import Wedding499Details from '../view/e_invite_bundles/Wedding499Details';
import Wedding799Details from '../view/e_invite_bundles/Wedding799Details';
import Wedding999Details from '../view/e_invite_bundles/Wedding999Details';
import VerifyOtp from '../login/VerifyOtp';
import BundlePaymentFailedScreen from '../view/orders/BundlePaymentFailedScreen';
import BundlePaymentWaitingScreen from '../view/orders/BundlePaymentWaitingScreen';
import BundlePaymentSuccessfulScreen from '../view/orders/BundlePaymentSuccessfulScreen';
import FormSubmissionPage from '../view/orders/FormSubmissionPage';
import CardSample from '../view/sample_page/CardSample';

import SendInvitationSuccessfull from '../view/payment_screen/SendInvitationSuccessfull';
import EditDigitalCard from '../view/digital-invite/EditDigitalCard';
import PublicPageForDigitalInvitationView from '../view/digital-invite/PublicPageForDigitalInvitationView';
import DigitalCards from '../view/digital-invite/DigitalCards';
import Loading from '../components/Loading';
import Holi2025Offer from '../view/e_invite_bundles/Holi2025Offer';
import NetajiSubscription from '../view/netaji_subscription/NetajiSubscription';

// TODO: path=   cards/engagement/engagement-Card-Id
const RouterSetup = () => {
  return (
    <Router>
      <Routes>
      <Route path='/netajiposter-user-subscription' element={<NetajiSubscription />} />
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='/coming-soon' element={<ComingSoon />} />
          <Route path='/android-app' element={<AndroidAppPage />} />
          <Route path='/ios-app' element={<IosAppPage />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/refund-policy' element={<RefundPolicy />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/delete-account-policy' element={<DeleteAccountAndPolicy />} />
          <Route path='/support-ticket' element={<SupportTicket />} />
          <Route path='/delete-account-request' element={<DeleteAccountPage />} />
          <Route path='/content-takedown-request' element={<ContentTakedownRequestPage />} />
          <Route path='/payment-success-screen' element={<PaymentSuccesssScreen />} />
          <Route path='/payment-waiting-screen' element={<PaymentWaitingScreen />} />
          <Route path='/payment-failed-screen' element={<PaymentFailed />} />
          <Route path='/card-submitted-successfully' element={<CardSubmittedSuccessfullyScreen />} />


          <Route path="cards" >
            <Route path='' element={<CardLayout />}>
              {/* Wedding category routes */}
              <Route path=':group_path' element={<InvitationCardMaster />} />
            </Route>
            <Route path=':group_path/:group_type/:card_id' element={<InvitationCard />} />
          </Route>

          <Route path="account" element={<Account />} />
          <Route path="drag-and-drop" element={<DragAndDrop />} />

          <Route path="login" element={<Login />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />

          <Route path="" element={<ProtectedRoutes />}>
            <Route path="dashboard" element={<Dashboard />}>
              <Route path="" element={<CompleteProfile />} />
              <Route path="complete-profile" element={<CompleteProfile />} />
              <Route path="my-cards" element={<MyCards />} />
              <Route path="digital-cards" >
                <Route path="" element={<DigitalCards/>}/>
                <Route path="edit-digital-card/:id" element={<EditDigitalCard/>}/>
              </Route>

              <Route path="invoices" element={<Invoices />} />
              <Route path="family-and-friends" element={<FaimlyAndFriends />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="my-profile" element={<MyProfile />} />
              <Route path="create/:profile" element={<CreateProfile />} />
              <Route path="edit/:profileId" element={<EditProfileDetails />} />
            </Route>

          </Route>
          <Route path="form" element={<Form />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="about-us" element={<AboutUsPage />} />
          <Route path="contact-us" element={<ContactUsPage />} />

          <Route path="diwali-offer" element={<DiwaliOffer />} />
          <Route path= "carousel" element={<CarouselComponent/>}/>
          <Route path= "bundle-order/:order_id" element={<PublicPageForOrders/>}/>

          <Route path="font-style" element={<FontStyle/>}/>
          <Route path="bundle-page" element={<BundlesPage/>}/>
          <Route path="bundle-code-details" element={<BundleCodeDetails/>}/>
          <Route path="wedding11-form" element={<Wedding11Details/>}/>
          <Route path="wedding49-form" element={<Wedding49Details/>}/>
          <Route path="wedding199-form" element={<Wedding199Details/>}/>
          <Route path="wedding499-form" element={<Wedding499Details/>}/>
          <Route path="wedding799-form" element={<Wedding799Details/>}/>
          <Route path="wedding999-form" element={<Wedding999Details/>}/>
          <Route path="special-offer" element={<Holi2025Offer/>}/>

          <Route path="payment-waiting/:bundle_id" element={<BundlePaymentWaitingScreen/>}/>
          <Route path="payment-failed/:bundle_id" element={<BundlePaymentFailedScreen/>}/>
          <Route path="payment-successful/:bundle_id" element={<BundlePaymentSuccessfulScreen/>}/>
          <Route path="form-submission/:bundle_id" element={<FormSubmissionPage/>}/>
          <Route path="card-sample" element={<CardSample/>}/>
          <Route path="send-invitation-successfull" element={<SendInvitationSuccessfull/>}/>

          
          <Route path="invite/:invitation_id" element={<PublicPageForDigitalInvitationView/>}/>
          <Route path='loading' element={<Loading/>}/>
          
          
          

        </Route>
      </Routes>
    </Router>
  )
}

export default RouterSetup;